import React, { useEffect, useState } from "react"

import Editor from "@monaco-editor/react"
import axios from "axios"
import { Box } from "@chakra-ui/layout"
import { Button } from "@chakra-ui/button"
import { Center } from "@chakra-ui/layout"

const Chat = props => {
  const [clientId, setClientId] = useState(undefined)
  const [websocketConnection, setWebsocketConnection] = useState(undefined)
  const [code, setCode] = useState("")
  const [isCodeRunning, setIsCodeRunning] = useState(false)

  useEffect(_ => {
    const initialClientId = `${Date.now()}`
    setClientId(initialClientId)

    const newWebsocketConnection = new WebSocket(
      `${process.env.GATSBY_WEBSOCKET_SECURITY_PREFIX}${process.env.GATSBY_API_HOST}/ws/${initialClientId}`
    )
    newWebsocketConnection.onmessage = event => {
      if (!event || !event.data) {
        return
      }
      console.log(`receiving event ${JSON.stringify(event.data)}`)

      const actualEvent = JSON.parse(event.data)
      if (actualEvent.clientId === clientId) {
        return
      }

      setCode(actualEvent.code)
    }

    setWebsocketConnection(newWebsocketConnection)
  }, [])

  function handleEditorChange(value, change) {
    setCode(value)
    if (!!websocketConnection) {
      websocketConnection.send(
        JSON.stringify({
          clientId: clientId,
          code: value,
        })
      )
    }
    console.log(`model value: ${value} and change: ${JSON.stringify(change)}`)
  }

  const runCode = _ => {
    console.log("trying to run dat code")
    setIsCodeRunning(true)
    try {
      axios
        .post(
          `${process.env.GATSBY_API_SECURITY_PREFIX}${process.env.GATSBY_API_HOST}/run-code`,
          {
            language: "python",
            code: code,
          }
        )
        .then(res => {
          setIsCodeRunning(false)
          alert(res.data)
          console.log(JSON.stringify(res))
        })
    } catch (err) {
      console.log(`dat err tho ${err}`)
    }
  }

  return (
    <Box>
      <Center>
        <Editor
          height="70vh"
          width="70vh"
          defaultLanguage="python"
          value={code}
          onChange={handleEditorChange}
          theme="vs-dark"
        />
      </Center>
      <Center>
        <Button
          isLoading={isCodeRunning}
          loadingText="Running"
          onClick={runCode}
        >
          Run Code
        </Button>
      </Center>
    </Box>
  )
}

export default Chat
