import React from "react"
import Chat from "../components/chat"

const ChatPage = props => {
  return (
    <div>
      <Chat />
    </div>
  )
}

export default ChatPage
